"use client";

import Link from "@/components/Link";
import { usePathname } from "next/navigation";
import { ReactNode } from "react";

export default function LoginLink({ children, className }: { children: ReactNode; className?: string }) {
  const pathname = usePathname();
  return (
    <Link href={`/api/auth/signin?callbackUrl=${pathname}`} className={className}>
      {children}
    </Link>
  );
}
