"use client";

import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { useCallback, useEffect, useState } from "react";

interface SearchProps {
  placeholder?: string;
  totalCount?: number;
  title?: string;
  primaryLine?: string;
  secondaryLine?: string;
}

export default function Search({
  placeholder = "Search",
  totalCount = 600000,
  title = "Discover with Orbic AI",
  primaryLine = "AI Tools, GPT Stores, and AWS PartyRocks —",
  secondaryLine = "registered pages and growing!",
}: SearchProps) {
  const [inputValue, setInputValue] = useState<string>("");
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const { replace } = useRouter();
  const query = searchParams.get("query");

  useEffect(() => {
    if (query) {
      setInputValue(decodeURIComponent(query));
    }
  }, [query]);

  const handleSearch = useCallback(
    async (term: string) => {
      const params = new URLSearchParams(searchParams);
      params.delete("page");
      if (term) {
        params.set("query", encodeURIComponent(term));
      } else {
        params.delete("query");
      }
      await replace(`${pathname}?${params.toString()}`);
    },
    [replace, pathname, searchParams]
  );

  return (
    <div className="grid grid-cols-12">
      <div className="col-span-12 mb-8 text-center">
        <h1 className="mb-2 px-4 text-4xl font-bold lg:text-6xl">{title}</h1>
        <p className="justify-center align-middle text-xl">
          <span className="mr-2">{primaryLine}</span>
          <span className="from-secondary to-primary inline-block bg-gradient-to-r bg-clip-text text-2xl font-bold text-transparent">
            {` ${Intl.NumberFormat().format(totalCount)} `}
          </span>
          <span className="ml-2">{secondaryLine}</span>
        </p>
      </div>
      <label className="input input-bordered col-span-12 mx-auto flex w-full max-w-3xl items-center">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="h-4 w-4 opacity-70">
          <path
            fillRule="evenodd"
            d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
            clipRule="evenodd"
          />
        </svg>
        <input
          type="text"
          className="w-full grow pl-4"
          placeholder={placeholder}
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
            handleSearch(e.target.value);
          }}
        />
      </label>
    </div>
  );
}
