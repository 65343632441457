"use client";

import { getBookmarkCountForSlug, toogleBookmark } from "@/actions/bookmarks";
import LoginLink from "@/components/LoginLink";
import { Bookmark } from "@prisma/client";
import React, { useCallback, useEffect } from "react";
import { useFormStatus } from "react-dom";
import { FaRegStar, FaStar } from "react-icons/fa";

function FormComponent(props: { formAction: () => Promise<void>; hasBookmarked: boolean }) {
  const { pending } = useFormStatus();
  return (
    <button
      className="tap-highlight-transparent data-[focus-visible=true]:outline-focus px-unit-4 min-w-unit-20 h-unit-10 text-small gap-unit-2 transition-transform-colors-opacity text-default-foreground relative z-0 box-border inline-flex h-5 select-none appearance-none items-center justify-center  whitespace-nowrap  bg-transparent font-normal subpixel-antialiased outline-none data-[focus-visible=true]:z-10 data-[pressed=true]:scale-[0.97]  data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-offset-2 motion-reduce:transition-none [&>svg]:max-w-[theme(spacing.unit-8)]"
      type="submit"
      disabled={pending}
      formAction={props.formAction}
    >
      {pending && <span className="loading loading-spinner loading-sm"></span>}
      {!pending && (
        <>{props.hasBookmarked ? <FaStar width={16} height={16} /> : <FaRegStar width={16} height={16} />}</>
      )}
    </button>
  );
}

interface BookmarkButtonProps {
  user: any;
  bookmarks: Array<Bookmark>;
  slug: string;
  count: number;
  showCount?: boolean;
}

const BookmarkButton: React.FC<BookmarkButtonProps> = ({ user, bookmarks, slug, count = 0, showCount = false }) => {
  const [bookmarkCount, setBookmarkCount] = React.useState(count);
  const [hasBookmarked, setHasBookmarked] = React.useState(!!bookmarks?.find((bookmark) => bookmark.slug === slug));

  const getBookmarkCount = useCallback(async (slug: string) => {
    const count = await getBookmarkCountForSlug(slug);
    setBookmarkCount(count);
  }, []);

  useEffect(() => {
    getBookmarkCount(slug);
  }, [hasBookmarked]);

  if (user?.id) {
    return (
      <div
        className="flex items-center gap-3"
        onClick={(e) => {
          console.log("BookmarkButton clicked");
          e.stopPropagation();
        }}
      >
        <form className="h-6">
          <div
            className={`flex items-center gap-2 rounded-md border px-1 ${hasBookmarked ? "badge-primary" : "badge-neutral"} ${showCount ? "" : "justify-center"}`}
          >
            <FormComponent
              formAction={async () => {
                await toogleBookmark(slug);
                setHasBookmarked(!hasBookmarked);
              }}
              hasBookmarked={hasBookmarked}
            />
          </div>
        </form>
        {showCount && (
          <div>
            Bookmarked by <span className="mb-[-2px] text-sm font-bold">{bookmarkCount}</span> User
          </div>
        )}
      </div>
    );
  }
  return (
    <LoginLink className="tap-highlight-transparent data-[focus-visible=true]:outline-focus px-unit-4 min-w-unit-20 h-unit-10 text-small gap-unit-2 transition-transform-colors-opacity text-default-foreground relative z-0 box-border inline-flex select-none appearance-none items-center justify-center gap-3  whitespace-nowrap  bg-transparent font-normal subpixel-antialiased outline-none data-[focus-visible=true]:z-10 data-[pressed=true]:scale-[0.97]  data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-offset-2 motion-reduce:transition-none [&>svg]:max-w-[theme(spacing.unit-8)]">
      <FaRegStar width={16} height={16} />
      {showCount && (
        <div>
          Bookmarked by <span className="mb-[-2px] text-sm font-bold">{bookmarkCount}</span> User
        </div>
      )}
    </LoginLink>
  );
};

export default BookmarkButton;
